<template>
  <div id="content">
    <div class="wrapper">
      <header class="mb-xxl-5">
        <h1>{{ $t('message.terms_of_use') }}</h1>
      </header>

      <div v-if="getLocale() === 'fr'">
        <div class="mb-xxl-5">
          <p>
            Les présentes Conditions Générales d’Utilisation (ci-après désignées les « CGU») visent à définir les conditions
            d’utilisation de la solution informatique dénommée « Mon eCoffre par FIDUCIAL »
            <br>
            <br>
            Ce service permet à un Client et ses Utilisateurs la possibilité de recevoir ou conserver des Documents au
            format électronique et sécurisé.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>PRÉAMBULE: DÉFINITIONS</u></h6>
          <p>
            Pour l'exécution et l'interprétation des présentes CGU, les mots suivants, dont la première lettre est en
            majuscule, qu’ils soient au singulier ou au pluriel, ont la signification qui leur est donnée ci-après :
            <br>
            <br>
            <b>Client :</b> désigne toute personne morale ou physique étant liée avec FIDUCIAL notamment par tout
            contrat, lettre de mission ou bon de commande et bénéficiant des services de Mon eCoffre par FIDUCIAL.
            <br>
            <br>
            <b>FIDUCIAL :</b> désigne d’une part la société FIDUCIAL CLOUD , Société par actions simplifiée au capital
            de <b>1.249.597,16</b> euros, dont le siège social est situé 41 rue du Capitaine Guynemer – 92400 Courbevoie,
            immatriculée au Registre du Commerce et des Sociétés de Nanterre, sous le numéro <b>480.650.712</b>, éditeur
            de Mon eCoffre par FIDUCIAL et d’autre part, toutes les sociétés du groupe FIDUCIAL autorisées à distribuer
            Mon eCoffre par FIDUCIAL.
            <br>
            <br>
            <b>Mon eCoffre par FIDUCIAL :</b> désigne l’espace de stockage sécurisé exploité par FIDUCIAL qui permet à
            l’Utilisateur, de recevoir, stocker, afficher et supprimer des Documents.
            <br>
            <br>
            <b>Compte FIDUCIAL:</b> Désigne le compte utilisateur permettant d’accéder, à l’aide d’une authentification
            unique, aux services en ligne proposés par les sociétés du groupe FIDUCIAL à leurs clients.
            <br>
            <br>
            <b>Documents :</b> désigne les fichiers de toute nature que l’Utilisateur peut recevoir ou déposer
            directement sur « Mon eCoffre par FIDUCIAL ».
            <br>
            <br>
            <b>Partie :</b> Désigne FIDUCIAL et ou  le CLIENT.
            <br>
            <br>
            <b>Utilisateurs :</b> désigne le Client ainsi que tout personnel, mandataires sociaux du Client utilisant
            les Services fournis par FIDUCIAL.
            <br>
            <br>
            <b>Site :</b> désigne le site Internet édité par FIDUCIAL permettant d’accéder à Mon eCoffre par FIDUCIAL
            accessible à l’URL suivante
            <a href="https://mon-ecoffre.com" target="_blank" rel="noopener noreferrer">https://mon-ecoffre.com</a>.
            <br>
            <br>
            <b>Services :</b> désignent la mise à disposition du Site et les services décrits à l’article 2.
            <br>
            <br>
            <b>Données :</b> désigne les données figurant dans documents des Utilisateur traitées par Mon eCoffre
            par FIDUCIAL y compris les données à caractère personnel au sens de la loi n°78-17 du 6 janvier 1978 et
            du règlement général sur la protection des données.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 1 : OBJET ET ACCEPTATION</u></h6>
          <p>
            En accédant au Site afin de lui permettre l’utilisation des Services, l’Utilisateur reconnaît avoir
            pris connaissance des présentes CGU et s’engage à les respecter.
            <br>
            <br>
            Toute connexion au Site, à l’aide d’un identifiant et d’un mot de passe, emporte pleine et entière
            acceptation des présentes CGU.
            <br>
            <br>
            Il est entendu entre les Parties que certaines stipulations des CGU de Mon eCoffre par FIDUCIAL ne
            seront pas applicables aux clients non- professionnels et aux consommateurs.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 2 : SERVICES FOURNIS</u></h6>
          <p>
            Mon eCoffre par FIDUCIAL est un espace de stockage sécurisé conforme à la norme NF Z 42 020 permettant à
            Utilisateur de recevoir et conserver des Documents dans un environnement hautement sécurisé.<br>
            Mon eCoffre par FIDUCIAL est accessible par l’Utilisateur depuis n’importe quel ordinateur, smartphone
            ou tablette connecté à Internet.<br>
            Il est entendu par l’Utilisateur que Mon eCoffre par FIDUCIAL ne constitue en aucun cas un Coffre Numérique
            tel que dispose l’article L 103 du Code des Postes et des Télécommunications.<br>
            Les Services prennent effet à compter de la date d'ouverture des Services, telle que définie à  Article 1.
            Les Services seront actifs  pendant toute la période de validité des Contrats ou Lettre de Mission conclus
            entre le Client à FIDUCIAL.<br>
            Les Services pourront être suspendus par le Client conformément aux conditions de résiliation des Contrat
            ou Lettre de Missions conclus entre FIDUCIAL et le CLIENT
            <br>
            <br>
            <b>2.1 Accessibilité</b>
            <br><br>
            Mon eCoffre par FIDUCIAL est accessible via le Site ou par le Compte FIDUCIAL de l’Utilisateur.<br>
            Avant toute utilisation de Mon eCoffre par FIDUCIAL, l’Utilisateur reconnaît :<br>
            <ul>
              <li>S’être assuré que le navigateur utilisé permet un accès sécurisé au Service;</li>
              <li>Avoir activé son Compte FIDUCIAL;</li>
              <li>Disposer d’une adresse mail valide.</li>
            </ul>
            <br>
            Mon eCoffre par FIDUCIAL est disponible 7 jours sur 7 et 24 heures sur 24 sauf périodes de maintenance,
            mises à jour, amélioration technique ou esthétique, interruption inopinée du Site, défaillance du réseau
            ou des intermédiaires techniques, difficultés informatiques ou techniques, ou tout cas fortuit ou évènement
            de force majeure.
            <br><br>
            <b>2.2 Informations de l’Utilisateur</b><br><br>
            L’Utilisateur garantit que les informations qu’il transmet sont exactes et s’engage à les mettre à jour
            le cas échéant.
            <br><br>
            Il appartient à l’Utilisateur de vérifier régulièrement ses coordonnées et de les mettre à jour au fur et
            à mesure des éventuels changements. En l’absence d’adresse email valide, FIDUCIAL sera dans l’impossibilité
            de respecter ses obligations et ne pourra pas être tenu pour responsable des éventuelles conséquences
            qui en résulteraient.
            <br><br>
            L’identifiant et le mot de passe de l’Utilisateur lui sont strictement personnels. Il s’oblige donc à les
            garder confidentiels. En cas de violation de la confidentialité de son identifiant et/ou mot de passe,
            l’Utilisateur devra avertir FIDUCIAL dans les plus brefs délais afin de garantir la sécurité du Compte.
            <br><br>
            <b>2.3 Stockage</b><br><br>
            Mon eCoffre par FIDUCIAL permet à l’Utilisateur d’afficher et de télécharger les Documents émis par FIDUCIAL
            pour le compte de sa société.
            <br><br>
            Avec Mon eCoffre par FIDUCIAL, l’Utilisateur dispose également de 1 Go de stockage pour conserver ses
            Documents personnels dans un endroit sécurisé. Ce second espace prend en charge des Documents pesant
            jusqu’à 5 Mo par fichier.
            <br><br>
            Cette offre de stockage est extensible depuis le Compte FIDUCIAL dans l’onglet «Mes abonnements»
            <br><br>
            Il est entendu entre les Parties que le Client pourra augmenter librement sa capacité des stockage,
            toutefois il ne pourra pas la réduire pendant la durée des Contrats/ et ou des Lettres de Missions
            conclus entre FIDUCIAL et le CLIENT  sauf accord écrit de FIDUCIAL.
            <br><br>
            <b>2.4 Chiffrage des Documents.</b><br>
            <br>
            Les Documents et Données de l’Utilisateur sont entièrement chiffrés selon la norme AES 256, avec une clef
            propre à chaque Utilisateur.
            <br><br>
            Les clefs de chiffrement sont elles-mêmes chiffrées avec une clef maîtresse. Les flux sont chiffrés de bout
            en bout selon le protocole HTTPS TLS.
            <br><br>
            La protection des accès est gérée par une couche de détection d’actions malignes via un Web Application
            Firewall (WAF).
            <br><br>
            <b>2.5 Traçabilité des accès et opérations réalisées dans Mon eCoffre par FIDUCIAL</b><br>
            <br>
            Les opérations suivantes sont garanties :
            <ul>
              <li>Dépôt d’un Document dans Mon eCoffre par FIDUCIAL,</li>
              <li>Consultation d’un Document dans Mon eCoffre par FIDUCIAL,</li>
              <li>Téléchargement d’un Document et d’une archive dans Mon eCoffre par FIDUCIAL,</li>
              <li>Connexions à Mon eCoffre par FIDUCIAL et Utilisateurs ayant accédé au service.</li>
            </ul>
            <br>
            Toutes ces informations sont stockées en base et dans un fichier trace. Un journal des actions est
            consultable au sein de Mon eCoffre par FIDUCIAL.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 3 : GARANTIES ASSURANCE ET RESPONSABILITÉS</u></h6>
          <p>
            <b>3.1 GARANTIES</b><br><br>

            FIDUCIAL, n’est tenue que d'une obligation de moyens en ce qui concerne le fonctionnement et la continuité
            des Services<br><br>

            FIDUCIAL ne peut en aucun cas être tenue responsable d’un quelconque dommage survenu à l’occasion de
            l’utilisation des Services.
            FIDUCIAL ne serait pas tenu pour responsable :<br>

            <ul>
              <li>des vitesses d'accès au Site, des vitesses d'ouverture et de consultation des pages du Site, de
                ralentissements externes, de la suspension ou de l'inaccessibilité du Site,</li>
              <li>des faits dus à un cas de force majeure tel que défini par l’article 1218 du Code Civil ou faits
                des tiers et notamment des pannes et des problèmes d'ordre technique concernant le matériel,
                des programmes, des logiciels, de réseau Internet pouvant le cas échéant, entraîner la suspension
                ou la cessation des Services,</li>
              <li>si le Site s'avère incompatible ou présente des dysfonctionnements avec certains logiciels,
                configurations, systèmes d'exploitation ou équipements que l’Utilisateur utilise,</li>
              <li>des interruptions de services liées aux aléas du réseau Internet et/ou aux opérations de maintenance.</li>
              <li>pour tout préjudice indirect, comme la perte de revenus ou d’intérêts que l’Utilisateur pourrait subir.</li>
            </ul>

            L’Utilisateur est seul responsable de l’utilisation des Services.<br><br>

            <b>3.2 RESPONSABILITE-ASSURANCE.</b><br><br>

            Chacune des Parties sera responsable civilement de tous les manquements fautifs ainsi que des conséquences
            dommageables résultant de l'inexécution des prestations mises à leur charge respectivement au titre de la
            réalisation des Services.<br><br>

            Néanmoins, à l'exception des dommages corporels ou manquements consécutifs à une faute intentionnelle ou
            dolosive imputable à FIDUCIAL, la responsabilité de FIDUCIAL au titre de ses erreurs, manquements et ou
            inexécutions dans le cadre de la réalisation des Services sera limitée par an, dans l’hypothèse d’une faute
            prouvée, à tous dommages confondus au montant figurant dans les contrats ou lettres de missions signées
            entre le CLIENT  et FIDUCIAL.<br><br>

            Le CLIENT accepte d'assumer les conséquences dommageables qui viendraient à dépasser le montant figurant
            dans les contrats de services ou lettres de missions signées entre l’Utilisateur et FIDUCIAL et renonce à
            rechercher la responsabilité de FIDUCIAL au-delà de cette limitation contractuelle.<br><br>

            FIDUCIAL déclare avoir souscrit auprès d'une compagnie d'assurance notoirement solvable une assurance
            garantissant les conséquences pécuniaires de sa responsabilité civile et contractuelle et sur simple demande
            du client s'engage à lui communiquer toute attestation d'assurance justifiant de cette couverture.<br><br>

            <b>3.3 RECLAMATION</b><br><br>

            FIDUCIAL ne pourra voir engagée sa responsabilité plus d’un (1) an après le jour de la réalisation du dommage
            ou la date à laquelle il est révélé à l’Utilisateur et si cette dernière établit qu'elle n'en avait pas eu
            connaissance précédemment. Ce délai écoulé, toute action sera prescrite.
            </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 4 : RÉSILIATION - CLÔTURE DES SERVICES</u></h6>
          <p>
            FIDUCIAL peut être contraint de clôturer les Services pour l’une des raisons suivantes :<br>
          <ul>
            <li>La suspicion d’usurpation de l’identité de l’Utilisateur,</li>
            <li>L’existence de manquements aux présentes CGU,</li>
            <li>Le non-respect par l’Utilisateur de la réglementation en vigueur,</li>
            <li>Sur demande d’une autorité compétente ou d’une décision de justice devenue définitive,</li>
            <li>La cessation de l’activité par FIDUCIAL,</li>
            <li>La résiliation du contrat / de la Lettre de mission conclu entre FIDUCIAL et le CLIENT</li>
          </ul>

          Dans ce cas, FIDUCIAL informera le CLIENT par courrier et/ou par e-mail, sur l’adresse e-mail renseignée,
            et indiquera les causes de la résiliation ou de la clôture des Services.<br><br>

          Il indiquera également les modalités suivant lesquelles l’Utilisateur pourra procéder au téléchargement de
            ses DOCUMENTS ainsi que la date de fermeture définitive des accès à Mon eCoffre par FIDUCIAL. L’Utilisateur
            aura alors un délai de 6 mois pour procéder au téléchargement de l’ensemble de ses DOCUMENTS.<br>
          L’Utilisateur est averti que passé ce délai de 6 mois à compter de la réception du courrier et/ou l’email
            stipulant l’arrêt des services , l’ensemble des Documents contenus dans Mon eCoffre par FIDUCIAL seront
            supprimés sans aucune possibilité de restauration.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 5 : PROPRIÉTÉ INTELLECTUELLE</u></h6>
          <p>
            Le Site et notamment son interface graphique, ses éléments textuels et visuels dont les marques et les
            logos qui y figurent, sont protégés par des droits de propriété intellectuelle dont est titulaire FIDUCIAL
            ou que FIDUCIAL est autorisé à utiliser.<br><br>

            Toute reproduction, imitation, représentation, modification, adaptation, traduction, extraction, par
            quelque procédé que ce soit et sur quelque support que ce soit, de tout ou partie du Site et notamment
            de son interface graphique, ses éléments textuels et visuels, sans avoir obtenu l’autorisation préalable
            écrite de FIDUCIAL, est susceptible de constituer une contrefaçon au sens des articles L335-2 et suivants
            du Code de la Propriété Intellectuelle et est strictement interdite. La reproduction ou la représentation
            du Site et de ses éléments est autorisée uniquement à des fins de consultation et de téléchargement des
            documents mis à la disposition de l’Utilisateur.<br><br>

            Toute reproduction, imitation, utilisation ou apposition de l’une quelconque des marques reproduites sur le
            Site, sans avoir obtenu l’autorisation préalable écrite de FIDUCIAL, est susceptible de constituer une
            contrefaçon au sens des articles L713-2 et suivants et L717-1 et suivants du Code de la propriété
            intellectuelle et est strictement interdite.<br><br>

            FIDUCIAL et/ou le titulaire des droits de propriété intellectuelle portant sur l’un quelconque des éléments
            du Site se réservent le droit d’engager des poursuites judiciaires à l’encontre de toute personne qui ne
            respecterait pas les interdictions précitées.<br>
            La création de liens hypertextes, profonds ou non, vers le Site est strictement interdite, sauf avec
            l’accord écrit préalable de FIDUCIAL.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 6 : DONNEES</u></h6>
          <p>
            L’Utilisateur est et demeure propriétaire ou investi des droits portant sur l’ensemble des Données qu’il
            utilise à travers Mon eCoffre par FIDUCIAL, et reste seul responsable des Données traitées par lui, sans
            préjudice de toute obligation incombant à FIDUCIAL en sa qualité de sous-traitant de données personnelles
            au sens de la réglementation.  FIDUCIAL ne saurait en aucune manière être responsable de la légalité des
            contenus traités par l’Utilisateur via Mon eCoffre par FIDUCIAL.<br><br>

            L’Utilisateur dégage FIDUCIAL de sa responsabilité à l'égard des tiers résultant du non-respect, par
            l’Utilisateur, des obligations lui incombant vis-à-vis des tiers et garantira à ce titre FIDUCIAL en cas
            de demande ou de recours à son encontre.<br><br>

            Si FIDUCIAL venait à être inquiétée de ce chef, elle en avisera l’Utilisateur qui devra prendre immédiatement
            les mesures propres à faire cesser tout trouble et prendra seul à ses frais toute mesure de défense nécessaire
            et indemnisera FIDUCIAL de tous dommages, intérêts et frais de dépens auxquels elle serait condamnée à ce chef
            par une décision de justice.<br><br>

            FIDUCIAL pourra résilier le Service, sans avoir à verser des dommages et intérêts ou une indemnité, si une
            décision de justice lui fait défense de faire bénéficier l’Utilisateur de ses Services, sauf si cette interdiction
            résulte d’une faute de FIDUCIAL.<br><br>

            L’Utilisateur a la qualité de « Responsable du Traitement » au sens de la réglementation et déclare respecter
            toutes obligations à cet effet en vue d’utiliser Mon eCoffre par FIDUCIAL, FIDUCIAL garantissant, en sa qualité
            de sous-traitant, que les Services seront réalisées conformément à la réglementation, et suivant les principes
            relatifs au traitement des données personnels visés dans les contrats de services ou lettres de missions signés
            avec FIDUCIAL.<br><br>

            FIDUCIAL ne conservera les Données et notamment les Données Personnelles que pendant la durée nécessaire à
            l’exécution des Services ou pendant toute période supplémentaire requise par la réglementation et convenue
            avec l’Utilisateur.<br><br>

            FIDUCIAL ne pourra en aucun cas utiliser les Données de l’Utilisateur à des fins autres que l’exécution
            des Services.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 7 : ASSISTANCE</u></h6>
          <p>
            Un service d’assistance utilisateur est disponible les jours ouvrés, du lundi au vendredi, pendant les
            heures ouvrables (de 9h à 17h, Heure d’Europe centrale) au numéro suivant 0821 21 25 25 (0,18€ TTC / minute).
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 8 : Confidentialité</u></h6>
          <p>
            Les documents contenus dans le Coffre électronique de l’Utilisateur sont strictement confidentiels et
            restent à l’usage unique de ce dernier.<br><br>

            Sont considérés comme confidentiels l’ensemble des documents et données contenus dans le Coffre
            électronique et pouvant être échangés entre les Parties.<br><br>

            Cette obligation de confidentialité, valable pour toute la durée d’utilisation des Services, court
            également pendant une période de cinq (5) ans à compter de la fin des Services. Cette extinction de
            l’obligation de confidentialité est sans préjudice de toute autre confidentialité qui perdurerait de par
            la loi, notamment au titre du secret des affaires.
            Chaque Partie s’engage, pendant cette période :<br>
          <ul>
            <li>à ne communiquer les informations confidentielles qu’aux membres de son personnel ou de ses sous-traitants
              éventuels qui sont dans la nécessité de les connaître pour l’exécution des Services ;</li>
            <li>à prendre les mesures qu’elle-même prend à l’égard de ses propres informations confidentielles pour en
              empêcher la divulgation ou la publication à des tiers ;</li>
            <li>à ne pas reproduire ou autoriser la reproduction de ces informations confidentielles sans l’accord
              préalable écrit de l’autre Partie ;</li>
            <li>à n’utiliser directement ou indirectement ces informations que dans le cadre de l’Utilisation des
              Services, sauf accord préalable exprès de l’autre Partie.</li>
          </ul>
          <br>
          Chaque Partie pourra bien entendu, sous la plus stricte confidentialité, communiquer les présentes et les
            documents y afférents aux organismes fiscaux ou sociaux en cas de contrôle ou à leurs Conseils.<br>
          Les dispositions relatives à la confidentialité sont applicables aux sous-traitants éventuels de chacune
            des Parties.<br>
          L’obligation de confidentialité ne s’applique pas :<br>
          <ul>
            <li>aux informations qui seraient tombées dans le domaine public autrement que du fait d’une des Parties;</li>
            <li>aux informations qui étaient déjà connues de la Partie bénéficiaire avant leur communication, à charge
              pour elle d’en fournir toute justification.</li>
            <li>au droit pour FIDUCIAL de mentionner, dans le cadre de références commerciales quel que soit le support,
              le nom du Client tant que ce dernier est client, et son logo/et ou sa marque sous réserve que ne figure
              aucun détail relatif aux Services réalisés.</li>
          </ul>
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 9 : Obligations sociales</u></h6>
          <p>
            Le personnel de FIDUCIAL affecté à la réalisation des Services travaille en toutes circonstances sous la
            responsabilité hiérarchique et disciplinaire de FIDUCIAL, qui contrôle la bonne exécution des prestations
            et suit régulièrement l’avancement des activités.<br><br>

            FIDUCIAL assure la gestion administrative, comptable et sociale de son personnel ainsi que la responsabilité
            de son affiliation à tous les organismes sociaux et son entière responsabilité vis-à-vis de la législation
            du travail.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 10 : Non sollicitation de personnel</u></h6>
          <p>
            Chacune des Parties est consciente des investissements effectués pour former ses collaborateurs, FIDUCIAL
            rappelant à cet effet que la stabilité de ses équipes est le gage d’une continuité de ses Services et En
            conséquence, chacune des Parties s'interdit de solliciter afin de le prendre à son service, directement ou
            indirectement par l'intermédiaire d'une société mère, d'une société filiale ou d'une société dans laquelle
            il détient une participation, le personnel de l’autre Partie.<br><br>

            Cette interdiction prend fin un (1) an après la fin de la réalisation des Services. En cas de contravention
            au présent article, la Partie contrevenante paiera, à titre d'indemnité forfaitaire, une somme égale aux
            douze derniers mois d'appointements bruts de la personne concernée.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 11 : Force majeure</u></h6>
          <p>
            Aucune des Parties aux présentes ne pourra être tenue responsable de l'inexécution, des manquements ou
            retards pris dans l'exécution de l'une quelconque de ses obligations qui seraient dues à la survenance
            d'un cas de force majeure, comme habituellement reconnu par la jurisprudence ou tels que raisonnablement
            non maîtrisable par l’une des Parties, tels que, notamment, les interdictions d’exporter résultant d’une
            décision gouvernementale, les restrictions de circulation imposées par des mesures gouvernementales
            empêchant une Partie d’exécuter ses obligations, les mouvements sociaux sectoriels ou nationaux, les
            blocages des transports, les actes de piratage informatique, les pannes électrique et téléphonique, et
            de réseaux de communication électronique, les redressements ou liquidations judiciaires des sous-traitants.<br><br>

            La force majeure suspend les obligations du Contrat relatif à la fourniture des Services pendant toute la
            durée de son existence.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 12 : Sous-traitance</u></h6>
          <p>
            FIDUCIAL reste libre de sous-traiter tout ou partie de ses Services.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 13 : Anticorruption</u></h6>
          <p>
            L’Utilisateur déclare connaître et respecter la réglementation française relative aux paiements illicites
            et notamment à la lutte contre la corruption, l'extorsion, le trafic d'influence ou le blanchiment d'argent.<br>
            FIDUCIAL s'est dotée d'un code de conduite, qui est consultable sur son site Internet ou disponible sur simple
            demande. Le Client s'engage à en respecter les principes et se porte fort du respect desdits principes par
            ses salariés et intervenants.<br><br>

            Dans l'hypothèse où un conflit d'intérêts ou une problématique d'indépendance surviendrait au cours de
            l'exécution des Services, l’Utilisateur en fera part immédiatement à FIDUCIAL et recherchera avec elle
            la solution la plus adaptée à la situation. Si aucune solution satisfaisante pour FIDUCIAL n'est trouvée,
            FIDUCIAL sera en droit de résilier immédiatement le Contrat, sans qu'aucune pénalité ou indemnité ne soit
            due au Client.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE  14 : Stipulations</u></h6>
          <p>
            Si une ou plusieurs stipulations des CGU sont tenues pour non valides ou déclarées telles en application
            d'une loi, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente, elle(s)
            sera (ont) réputée(s) non écrite(s); les autres stipulations garderont toute leur force et portée.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 15 : Intégralité dES CGU</u></h6>
          <p>
            Les CGU contiennent l’intégralité des obligations des Parties. Les dispositions des CGU sont exclusives de
            toutes autres. Elles annulent et remplacent toutes propositions, accords ou protocoles et prévalent sur
            toutes autres communications entre les Parties, se rapportant à l'objet des CGU, faites ou non pendant
            son exécution.<br><br>

            Aucune indication, aucun document, ne pourra engendrer des obligations non comprises dans les CGU, s'ils
            n'ont fait l'objet d'un avenant signé par les Parties. Les annexes et avenants ultérieurs éventuels font
            partie intégrante des CGU et sont soumis à l'ensemble des dispositions qui le régissent.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 16 : Evolution des CGU</u></h6>
          <p>
            FIDUCIAL se réserve le droit de faire évoluer les présentes CGU, sous réserve de les notifier par courrier
            électronique au Client au moins cinq (5) mois avant leur entrée en vigueur. Dès lors et sauf à avoir fait
            part de son souhait de ne pas reconduire les CGU au titre de la période renouvelée, ces nouvelles CGU seront
            réputées acceptées par l’Utilisateur Client et entreront en vigueur au titre de la période renouvelée suivant
            la période à laquelle ces nouvelles conditions ont été notifiées.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 17 : Titre des articles des CGU</u></h6>
          <p>
            Les titres des articles des CGU sont insérés dans le seul but d'en faciliter la référence et ne peuvent
            être utilisés pour donner une interprétation à ces articles ou en affecter la signification. Aussi, en
            cas de difficulté d’interprétation entre l’un quelconque des titres et l’une quelconque des clauses
            constituant les CGU, les titres seront déclarés comme inexistants.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 18 : DROIT APPLICABLE ET ATTRIBUTION DE COMPÉTENCE</u></h6>
          <p>
            Les présentes CGU sont soumises au droit français.<br><br>

            Tout différend pouvant naître entre les Parties à propos de la validité, de l’interprétation, de l'exécution ou,
            plus généralement, des présentes Conditions d’Utilisation sera soumis à la compétence du Tribunal de commerce de
            Nanterre pour le Client professionnel.<br><br>

            Sous peine de forclusion, toute réclamation en dommages et intérêts devra être introduite devant la juridiction
            consulaire dans les trois (3) mois suivant la date à laquelle l’Utilisateur professionnel aura eu
            connaissance du sinistre.<br><br>

            Pour le Client non-professionnel ou consommateur, la juridiction territorialement compétente est celle du lieu
            où demeure le défendeur.
          </p>
        </div>
      </div>

      <div v-if="getLocale() === 'en'">
        <div class="mb-xxl-5">
          <p>
            These General Terms of Service (hereinafter referred to as the “GTS”) aim to define the conditions of use
            of the IT solution called “Mon eCoffre par FIDUCIAL”.
            <br>
            <br>
            This service allows a Client and his users the possibility to receive or store Documents in electronic
            and secure format.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>PREAMBLE: DEFINITIONS</u></h6>
          <p>
            For the execution and interpretation of these GTS, the following words, whose first letter is a capital
            letter, whether singular or plural, have the meaning given to them below:
            <br>
            <br>
            <b>Client :</b> refers to any legal or natural person that is linked with FIDUCIAL, in particular by any
            contract, assignment letter or purchase order, and that benefits from the services of “Mon eCoffre par FIDUCIAL”.
            <br>
            <br>
            <b>FIDUCIAL :</b> means on the one hand the company FIDUCIAL CLOUD, a “Société par actions simplifiée”
            (simplified joint-stock company) with a capital of <b>1.249.597,16</b> euros, dont le siège social est
            , whose registered office is located at 41 rue du Capitaine Guynemer – F-92400 Courbevoie - France, registered
            in the Nanterre (France) Trade and Companies Register, under number <b>480.650.712</b>, Publisher of
            “Mon eCoffre par FIDUCIAL” and on the other hand, all companies of the FIDUCIAL group authorised to
            distribute “Mon eCoffre par FIDUCIAL”.
            <br>
            <br>
            <b>“Mon eCoffre par FIDUCIAL” :</b> refers to the secure storage space operated by FIDUCIAL that allows
            the User to receive, store, display and delete Documents.
            <br>
            <br>
            <b>FIDUCIAL Account:</b> refers to the user account enabling access, by means of a unique authentication,
            to the online services offered by the companies of the FIDUCIAL group to their clients.
            <br>
            <br>
            <b>Documents :</b> refers to files of any kind that the User may receive or deposit directly
            on “Mon eCoffre par FIDUCIAL".
            <br>
            <br>
            <b>Party :</b> means FIDUCIAL and/or the CLIENT.
            <br>
            <br>
            <b>Users :</b> refers to the Client and any staff and company representatives of the Client using the
            Services provided by FIDUCIAL.
            <br>
            <br>
            <b>Site :</b> efers to the website published by FIDUCIAL allowing access to “Mon eCoffre par FIDUCIAL”
            accessible at the following URL
            <a href="https://mon-ecoffre.com" target="_blank" rel="noopener noreferrer">https://mon-ecoffre.com</a>.
            <br>
            <br>
            <b>Services :</b> refers to the provision of the Site and the services described in article 2.
            <br>
            <br>
            <b>Data :</b> refers to the data contained in the User Documents processed by “Mon eCoffre par FIDUCIAL”,
            including personal data within the meaning of the French law Nr 78-17 of 6 January 1978 and the General
            Regulation on Data Protection.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 1: PURPOSE AND ACCEPTANCE</u></h6>
          <p>
            By accessing the Site with the objective to use the Services, the User acknowledges having read these
            GTS and commits to respecting them.
            <br>
            <br>
            Any connection to the Site, using a username and a password, implies full and complete acceptance of
            these GTS.
            <br>
            <br>
            It is agreed between the Parties that certain stipulations of the GTS of “Mon eCoffre par FIDUCIAL”
            are not applicable to non-professional clients and consumers.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 2: SERVICES PROVIDED</u></h6>
          <p>
            “Mon eCoffre par FIDUCIAL” is a secure storage space compliant with the NF Z 42 020 standard allowing the
            User to receive and store Documents in a highly secure environment.<br>

            The User can access “Mon eCoffre par FIDUCIAL” from any computer, smartphone or tablet connected to the internet.<br>

            It is agreed by the User that “Mon eCoffre par FIDUCIAL” does not under any circumstances constitute a
            Digital Safe as defined in Article L 103 of the French Post and  Telecommunications Code.<br>

            The Services take effect from the date the Services are opened, as defined in Article 1. The Services will
            be active during the entire period of validity of the Contracts or Assignment Letters concluded between
            the Client and FIDUCIAL.<br>

            The Services may be suspended by the Client in accordance with the conditions of termination of the
            Contract or Assignment Letter entered into with FIDUCIAL and the CLIENT.
            <br>
            <br>
            <b>2.1 Accessibility</b>
            <br><br>
            “Mon eCoffre par FIDUCIAL” is accessible via the Website or via the User’s FIDUCIAL Account.<br>
            Before any use of “Mon eCoffre par FIDUCIAL”, the user acknowledges:<br>
            <ul>
              <li>Having ensured that the browser used allows secure access to the Service;</li>
              <li>Having activated his FIDUCIAL Account;</li>
              <li>Having a valid email address.</li>
            </ul>
            <br>
            “Mon eCoffre par FIDUCIAL” is available 24/7, except during maintenance periods, updates, technical or
            aesthetic improvement, unexpected interruption of the Site, failure of the network or of  technical
            intermediaries, computer or technical difficulties, or any fortuitous event or force majeure.
            <br><br>
            <b>2.2 User information</b><br><br>
            The User guarantees that the information he gives is accurate and he commits to updating it as necessary.
            <br><br>
            It is the User’s responsibility to regularly check his contact details and to update them as and when any
            changes occur. In the absence of a valid email address, FIDUCIAL will be unable to meet its obligations and
            shall not be held liable for any consequences resulting therefrom.
            <br><br>
            The User’s username and password are strictly personal to the User. He therefore commits to keeping them
            confidential. In the event of a confidentiality breach of his username and/or password, the User must notify
            FIDUCIAL as soon as possible in order to guarantee the Account’s security.
            <br><br>
            <b>2.3 Storage</b><br><br>
            “Mon eCoffre par FIDUCIAL” allows the User to view and download Documents issued by FIDUCIAL on behalf
            of his company.
            <br><br>
            With “Mon eCoffre par FIDUCIAL”, the User also has at his disposal 1 GB storage space to keep personal
            Documents in a secure place. This second space supports Documents measuring up to 5 MB per file.
            <br><br>
            This storage offer is expandable from the FIDUCIAL account in the section “My Subscriptions”
            <br><br>
            It is agreed between the Parties that the Client may freely increase his storage capacity, he can however
            not reduce it during the duration of the Contracts/ and/or Assignment Letters concluded between FIDUCIAL
            and the CLIENT unless otherwise agreed in writing by FIDUCIAL.
            <br><br>
            <b>2.4 Encryption of documents.</b><br>
            <br>
            User Documents and Data are fully encrypted according to the AES 256 standard, with a specific key for each User.
            <br><br>
            The encryption keys are themselves encrypted with a master key. Flows are encrypted from end to end using
            the HTTPS TLS protocol.
            <br><br>
            Access protection is managed by a malware detection layer via a Web Application Firewall (WAF).
            <br><br>
            <b>2.5 Traceability of access and operations carried out in “Mon eCoffre par FIDUCIAL”</b><br>
            <br>
            The following operations are guaranteed:
            <ul>
              <li>Deposit of Documents in “Mon eCoffre par FIDUCIAL”,</li>
              <li>Viewing of Documents in “Mon eCoffre par FIDUCIAL”,</li>
              <li>Downloading of Documents and archives in “Mon eCoffre par FIDUCIAL”,</li>
              <li>Connections to “Mon eCoffre par FIDUCIAL” and Users who have accessed the service.</li>
            </ul>
            <br>
            All this information is stored in basic and in a trace file. An action log can be read within
            “Mon eCoffre par FIDUCIAL”.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 3: GUARANTEES, INSURANCE AND RESPONSIBILITIES</u></h6>
          <p>
            <b>3.1 GUARANTEES</b><br><br>

            FIDUCIAL is only bound by an obligation of means with regard to the operation and continuity of
            the Services.<br><br>

            FIDUCIAL can in no case be held responsible for any damage incurred during the use of the Services.
            FIDUCIAL can not be held liable for:<br>

            <ul>
              <li>access speeds to the Site, speeds of opening and viewing pages of the Site, external slowdowns,
                suspension or inaccessibility of the Site,</li>
              <li>facts due to a case of force majeure as defined by article 1218 of the French Civil Code or facts of
                third parties and in particular breakdowns and technical problems concerning the hardware, programs,
                software, Internet network that may in some cases cause the suspension or termination of the Services,</li>
              <li>if the Site proves to be incompatible or if it malfunctions with certain software, configurations,
                operating systems or equipment that the User uses,</li>
              <li>interruptions in services due to the hazards of the Internet network and/or maintenance operations,</li>
              <li>for any indirect damage, such as loss of income or interests that the User may suffer. The User is
                solely responsible for the use of the Services.</li>
            </ul>

            The User is solely responsible for the use of the Services.<br><br>

            <b>3.2 LIABILITY-INSURANCE.</b><br><br>

            Each of the Parties will be subject to civil liability for all wrongful breaches as well as for the
            harmful consequences resulting from the non-performance of the services for which they are respectively
            responsible as part of the performance of the Services.<br><br>

            However, with the exception of personal injuries or breaches resulting from intentional or fraudulent
            misconduct attributable to FIDUCIAL, FIDUCIAL’s liability in respect of its errors, breaches and/or
            non-performance in connection with the performance of the Services will be limited per year, in the event
            of a proven fault, to, all damages combined, the amount contained in the contracts or assignment letters
            signed between the CLIENT and FIDUCIAL.<br><br>

            The CLIENT agrees to assume the harmful consequences that would exceed the amount contained in the
            service contracts or assignment letters signed between the User and FIDUCIAL and waives to seek
            FIDUCIAL’s liability beyond this contractual limitation.<br><br>

            FIDUCIAL declares that it has subscribed an insurance with an insurance company known to be solvent,
            guaranteeing the pecuniary consequences of its civil and contractual liabilities and commits to
            communicating, on simple request of the client, any proof of insurance justifying this coverage.<br><br>

            <b>3.3 CLAIM</b><br><br>

            FIDUCIAL will not be held liable more than one (1) year after the date on which the damage occurred or
            the date on which it was revealed to the User and if the latter establishes that it had not previously
            known of it. Once this time has elapsed, any action will be prescribed.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 4: TERMINATION - CLOSURE OF SERVICES</u></h6>
          <p>
            FIDUCIAL may be forced to close the Services for any of the following reasons:<br>
            <ul>
              <li>Suspicion of usurpation of the User’s identity,</li>
              <li>The existence of breaches of these GTS,</li>
              <li>Non-compliance by the User with the regulations in force,</li>
              <li>At the request of a competent authority or of a court decision that has become final,</li>
              <li>The cessation of activity by FIDUCIAL,</li>
              <li>Termination of the contract / assignment letter concluded between FIDUCIAL and the CLIENT.</li>
            </ul>

            In this case, FIDUCIAL will inform the CLIENT by post and/or email, to the email address provided, and will
            indicate the causes of the termination or closure of the Services.<br><br>

            FIDUCIAL will also indicate the ways by which the User can proceed to the download of his DOCUMENTS as well
            as the date of final closure of access to “Mon eCoffre par FIDUCIAL”. The User will then have 6 months time
            to proceed to the download of all his DOCUMENTS.<br>
            The User is warned that passed this period of 6 months starting on the date of receipt of the email
            informing of the termination of the services, all the Documents in “Mon eCoffre par FIDUCIAL” will be
            deleted without any possibility of restoring.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 5: INTELLECTUAL PROPERTY</u></h6>
          <p>
            The Site and in particular its graphical interface, its textual and visual elements including the trademarks
            and logos appearing on it, are protected by intellectual property rights owned by FIDUCIAL or which FIDUCIAL
            is authorized to use.<br><br>

            Any reproduction, imitation, representation, modification, adaptation, translation, extraction, by any process
            whatsoever and on any medium whatsoever, of all or part of the Site and in particular its graphical interface,
            its textual and visual elements, without having obtained FIDUCIAL’s prior written consent, is likely to
            constitute an infringement within the meaning of articles L335-2 and following of the French Code of
            Intellectual Property and is strictly prohibited. The reproduction or representation of the Site and
            its elements is authorised only for the purpose of reading and downloading the documents made available
            to the User.<br><br>

            Any reproduction, imitation, use or affixing of any of the trademarks reproduced on the Site, without
            FIDUCIAL’s prior written consent, is likely to constitute an infringement within the meaning of articles
            L713-2 et seq. and L717-1 et seq. of the French Intellectual Property Code and is strictly prohibited.<br><br>

            FIDUCIAL and/or the holder of intellectual property rights relating to any of the elements of the Site
            reserve the right to take legal action against any person who does respect the aforementioned prohibitions.<br>
            The creation of hypertext links, deep or not, to the Site is strictly prohibited, except with FIDUCIAL’s
            prior written consent.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 6: DATA</u></h6>
          <p>
            The User is and remains the owner or entitled to the rights over all the Data he uses through
            “Mon eCoffre par FIDUCIAL”, and remains solely responsible for the Data processed by him, without prejudice
            to any obligation incumbent on FIDUCIAL in its capacity of personal data processor within the meaning of the
            regulations. FIDUCIAL cannot, in any circumstances, be responsible for the legality of the contents
            processed by the User via “Mon eCoffre par FIDUCIAL”.<br><br>

            The User frees FIDUCIAL from its liability towards third parties resulting from the User’s non-compliance
            with his obligations towards third parties and will therefore guarantee FIDUCIAL in the event of a
            request or claim against FIDUCIAL.<br><br>

            If FIDUCIAL were to be concerned in this respect, FIDUCIAL will notify the User who must immediately take
            appropriate measures to stop any trouble and must take alone, and at his expense, any necessary defence
            measure and indemnify FIDUCIAL for all damages, interests and costs if FIDUCIAL were to be sentenced by
            a court decision.<br><br>

            FIDUCIAL may terminate the Service, without having to pay damages and interests or compensation, if a
            court decision forbids if from allowing the User to benefit from its Services, unless this prohibition
            results from a fault on the part of FIDUCIAL.<br><br>

            The User has the status of “Data Controller” within the meaning of the regulations and declares to
            comply with all obligations to this effect in order to use “Mon eCoffre par FIDUCIAL”, FIDUCIAL
            guaranteeing, in its capacity as subcontractor, that the Services will be carried out in accordance
            with the regulations and with the  principles relating to the processing of personal data referred
            to in the service contracts or assignment letters signed with FIDUCIAL.<br><br>

            FIDUCIAL will only retain the Data and in particular the Personal Data for the period necessary for the
            performance of the Services or for any additional period required by the regulations and agreed with
            the User.<br><br>

            FIDUCIAL shall under no circumstances use the User’s Data for any purpose other than the performance of
            the Services.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 7: SUPPORT</u></h6>
          <p>
            A support service is available from Monday through Friday, during working hours (from 9 am to 5 pm, CET)
            at the following number 0821 21 25 25 (0,18 € incl. VAT / minute).
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 8: Confidentiality</u></h6>
          <p>
            The documents contained in the User’s electronic safe are strictly confidential and are reserved for the
            exclusive use of the User.<br><br>

            All documents and data contained in the electronic safe and that may be exchanged between the Parties are
            considered as confidential.<br><br>

            This obligation of confidentiality, valid for the entire duration of use of the Services, also runs for a
            period of five (5) years after the termination of the Services. The extinction of the confidentiality
            obligation is without prejudice to any other confidentiality that may continue to exist by law,
            in particular as part of business secrecy. During this period, each Party commits to:<br>
            <ul>
              <li>communicate confidential information only to those members of their staff or sub-contractors, if any,
                who need to know such information in order to perform the Services;</li>
              <li>to take the measures they take themselves in respect of their own confidential information to prevent
                disclosure or publication to third parties;</li>
              <li>not to reproduce or authorize the reproduction of such confidential information without the prior
                written consent of the other Party;</li>
              <li>To use such information, directly or indirectly, only in the context of the Use of the Services,
                except with the express prior consent of the other Party.</li>
            </ul>
            <br>
            Each Party may of course, in the strictest confidence, communicate these documents to tax or social security
            bodies in the event of an audit or to their Advisers.<br>
            The provisions concerning confidentiality apply to any potential subcontractor of each of the Parties.<br>
            The confidentiality obligation does not apply to:<br>
            <ul>
              <li>information which has become public knowledge other than through the action of one of the Parties;</li>
              <li>information that was already known to the beneficiary Party before it was communicated,
                on condition that this Party provides full justification.</li>
              <li>the right of FIDUCIAL to mention, in the context of commercial references whatever the medium,
                the name of the Client as long as the latter is a client, and his logo/and or brand provided that
                no details relating to the Services performed appear.</li>
            </ul>
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 9: Social obligations</u></h6>
          <p>
            The FIDUCIAL employees that are assigned to perform the Services, work in all circumstances under FIDUCIAL’s
            hierarchical and disciplinary responsibility, who supervises the correct performance of the services and
            regularly monitors the progress of the activities.<br><br>

            FIDUCIAL is responsible for the administrative, accounting and social management of its staff, as well
            as their affiliation to all social organisations and has full responsibility with regard to employment
            legislation.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 10: Non-solicitation of personnel</u></h6>
          <p>
            Each of the Parties is aware of the investment made in training its staff, and FIDUCIAL points out that the
            stability of its teams is the guarantee of continuity of its Services. Consequently, each of the Parties
            undertake to not solicit the staff of the other Party with the goal of hiring them, either directly or
            indirectly through a parent company, a subsidiary or a company in which it is a stakeholder.<br><br>

            This prohibition ends one (1) year after the completion of the Services. In the event of a breach of this
            Article, the offending Party shall pay, by way of lump-sum compensation, a sum equal to the last twelve
            months' gross salary of the person concerned.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 11: Force majeure</u></h6>
          <p>
            None of the Parties hereto shall be held liable for any non-performance, failure or delay in the performance
            of any of its obligations which may be due to the occurrence of a case of force majeure, as usually
            recognized by case law or as reasonably uncontrollable by one of the Parties, such as, in particular,
            export prohibitions resulting from a governmental decision,  movement restrictions imposed by government
            measures preventing a Party from fulfilling its obligations, sectoral or national social movements,
            transport blockages, acts of computer piracy, power and telephone outages, and electronic communication
            networks, judicial reorganisation or legal liquidation of subcontractors.<br><br>

            Force majeure suspends the obligations of the Contract relating to the provision of the Services
            for the duration of its existence.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 12: Subcontracting</u></h6>
          <p>
            FIDUCIAL has the liberty to subcontract all or part of its Services.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 13: Anti-corruption</u></h6>
          <p>
            The User declares that he is aware of and complies with the French regulations relating to illicit payments
            and in particular the fight against corruption, extortion, influence peddling or money laundering.<br>
            FIDUCIAL has adopted a code of conduct, which can be read on its website or is available upon request.
            The client undertakes to respect the principles and vouches for the respect of the said principles by
            its employees and stakeholders.<br><br>

            In the event that a conflict of interest or independence issue arises during the execution of the Services,
            the User will immediately inform FIDUCIAL and will seek with it the most appropriate solution to the
            situation. If no satisfactory solution for FIDUCIAL is found, FIDUCIAL shall be entitled to terminate
            the Contract immediately, without any penalty or compensation being due to the Client.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 14: Stipulations</u></h6>
          <p>
            If one or more stipulations of the GTS are held to be invalid or declared as such pursuant to a law, a
            regulation or following a final decision of a competent court, it will be deemed unwritten; the other
            stipulations will retain their full force and scope.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 15: Entire GTS</u></h6>
          <p>
            The GTS contain all the obligations of the Parties. The provisions of the GTS are exclusive of all others.
            They cancel and replace any proposals, agreements or protocols and prevail over all other communications
            between the Parties, relating to the subject matter of the GTS, whether or not made during the
            execution of the Contract.<br><br>

            No indication, no document, may give rise to obligations not included in the GTS, if they have not been the
            subject of an amendment signed by the Parties. Any annexes and subsequent amendments are an integral part
            of the GTS and are subject to all the provisions governing it.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 16: Evolution of the GTS</u></h6>
          <p>
            FIDUCIAL reserves the right to change these GTS, subject to notifying them by email to the Client at least
            five (5) months before their entry into force. Therefore and unless FIDUCIAL has expressed its wish to not
            renew the GTS for the renewed period, these new GTS will be deemed accepted by the Client User and will
            enter into force for the renewed period following the period for which these new conditions have been
            notified.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 17: Title of GTS articles</u></h6>
          <p>
            The titles of the articles of the GTS are inserted for the sole purpose of facilitating their reference
            and may not be used to give an interpretation to these articles or to affect their meaning. Also, in the
            event of difficulty of interpretation between any of the titles and any of the clauses constituting the
            GTS, the titles will be declared non-existent.
          </p>
        </div>

        <div class="mb-xxl-5">
          <h6 class="font-weight-bold mb-xxl-3"><u>ARTICLE 18: GOVERNING LAW AND ASSIGNMENT OF JURISDICTION</u></h6>
          <p>
            These GTS are subject to French law.<br><br>

            Any dispute that may arise between the Parties concerning the validity, interpretation, performance or,
            more generally, these Terms of Service shall be submitted to the jurisdiction of the Nanterre Commercial
            Court for the professional Clients.<br><br>

            Under penalty of foreclosure, any claim for damages must be brought before the consular jurisdiction within
            three (3) months from the date on which the professional User becomes aware of the claim.<br><br>

            For the non-professional Client or consumer, the territorially competent jurisdiction is that of the place
            where the defendant resides.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'TermsOfService',
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Conditions Générales d’Utilisation'
  },
  methods: {
    getLocale () {
      return localStorage.getItem('locale') || 'fr'
    }
  }
}
</script>
